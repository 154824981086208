
import axios from '@/common/js/request'

export function selectLa(params){  // 模板下拉(包含停用的)
    const selectLa = axios({
        url: '/checkTemplate/selectLa',
        method: 'get',
        params
    })
    return selectLa
}

export function selectTemplLa(params){  // 模板下拉(不包含停用的)
    const selectTemplLa = axios({
        url: '/checkTemplate/selectTemplLa',
        method: 'get',
        params
    })
    return selectTemplLa
}

export function selectTask(params){  // 查询任务列表
    const selectTask = axios({
        url: '/checkTask/selectTask',
        method: 'get',
        params
    })
    return selectTask
}

export function closeTask(params){  // 关闭任务
    const closeTask = axios({
        url: '/checkTask/closeTask',
        method: 'get',
        params
    })
    return closeTask
}

export function deleteTask(params){  // 删除任务
    const deleteTask = axios({
        url: '/checkTask/deleteTask',
        method: 'get',
        params
    })
    return deleteTask
}

export function saveTask(data){  // 添加任务
    const saveTask = axios({
        url: '/checkTask/saveTask',
        method: 'post',
        data
    })
    return saveTask
}

export function updateTask(data){  // 编辑任务
    const updateTask = axios({
        url: '/checkTask/updateTask',
        method: 'post',
        data
    })
    return updateTask
}

export function selectPersonList(params){  // 安检员下拉框
    const selectPersonList = axios({
        url: '/checkData/selectPersonList',
        method: 'get',
        params
    })
    return selectPersonList
}
export function getIndoorRectify(params){  //
    const getIndoorRectify = axios({
        url: '/outside/getIndoorRectify',
        method: 'get',
        params
    })
    return getIndoorRectify
}
// 事件巡检
export function dataPage(params){  //
    const dataPage = axios({
        url: '/patrol/event/dataPage',
        method: 'get',
        params
    })
    return dataPage
}
// 事件异常导出
export function excelEventData(params){  //
    const excelEventData = axios({
        url: '/patrol/event/excelEventData',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return excelEventData
}