<template>
  <div>
    <!-- 外网验收-看压验收 -->
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
          工程编号/名称:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.searchText"
          ></el-input>
          <span style="margin-right: 20px"></span>
          安装施工队:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.install"
          ></el-input>
          <span style="margin-right: 20px"></span>
          现场管理员:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.scene"
          ></el-input>
          <span style="margin-right: 20px"></span>
          看压状态:
          <el-select
            v-model="searchBox.result"
            clearable
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div style="margin-top: 10px">
            申请时间:
            <el-date-picker
              size="small"
              v-model="time"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              placeholder="选择日期时间"
              @change="timeChange"
            >
            </el-date-picker>
            <span style="margin-right: 20px"></span>
            <el-button
              type="primary"
              class="SearchBtn"
              size="small"
              @click="search"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="SearchBtn"
              size="small"
              @click="resetData"
              >重置</el-button
            >
          </div>
        </div>
        <div class="table">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 390px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="manager"
              label="现场管理员"
              width="100"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="install"
              label="安装施工队"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="scope"
              label="看压范围"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <el-tag>{{ row.scope == 1 ? "中压" : "低压" }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="createBy"
              label="申请人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="申请时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="preceptImg"
              label="打压方案"
              align="center"
              width="100"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <el-button type="text" size="small" @click="showImg(row)"
                  >查看</el-button
                >
                <!-- <el-button type="text" @click="detilScheme(row.preceptImg)">查看</el-button> -->
              </template>
            </el-table-column>
            <el-table-column
              prop="stateName"
              label="看压状态"
              align="center"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" align="left" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <div v-if="row.state == 1">-</div>
                <el-button
                  v-else
                  type="primary"
                  size="small"
                  @click="seeDetil(row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="看压详情"
      :visible.sync="detilDialog1"
      width="40%"
      class="detil_dialog"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="审核信息" name="1">
          <div style="max-height: 600px; overflow: auto">
            <p class="detil-code">审核信息</p>
            <el-row v-if="currentRow.state > 2">
              <el-col :span="24">
                <div class="item-list">
                  看压部门： {{ currentRow.applyDeptName }}
                </div>
              </el-col>
            </el-row>
            <!-- 未审核的情况先隐藏 -->
            <!-- <div v-if="currentRow.state > 2"> -->
            <div class="item-list">
              审核结果：{{ currentRow.state >= 3 ? "审核通过" : "审核驳回" }}
            </div>
            <div class="item-list" v-if="currentRow.state == 2">
              驳回原因：{{ currentRow.cause }}
            </div>
            <div class="item-list">审核人： {{ currentRow.audit1 }}</div>
            <div class="item-list">审核时间： {{ currentRow.audit1Date }}</div>
            <!-- </div> -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="派工信息" name="2">
          <p class="detil-code">派工信息</p>
          <div
            v-if="
              currentRow.pressureApplyUsers &&
              currentRow.pressureApplyUsers.length > 0
            "
          >
            <div
              v-for="(item, index) in currentRow.pressureApplyUsers"
              :key="index"
            >
              <el-row>
                <el-col :span="8">
                  <div class="item-list">看压人： {{ item.realname }}</div>
                </el-col>
                <el-col :span="8">
                  <div class="item-list">派工时间： {{ item.createTime }}</div>
                </el-col>
                <el-col :span="8">
                  <div class="item-list">派工人： {{ item.createBy }}</div>
                </el-col>
              </el-row>
              <div class="item-list" v-if="item.cause">
                增加派工原因：{{ item.cause }}
              </div>
            </div>
          </div>
          <div v-else>没有相关信息</div>
        </el-tab-pane>
        <el-tab-pane label="核对信息" name="3">
          <p class="detil-code">核对信息</p>
          <div v-if="currentRow.state >= 5">
            <div class="item-list">
              核对结果：{{
                currentRow.state >= 5 && currentRow.state != 6
                  ? "核对通过"
                  : "核对驳回"
              }}
            </div>
            <div class="item-list" v-if="currentRow.cause1">
              驳回原因：{{ currentRow.cause1 }}
            </div>
            <div class="item-list">核对人：{{ currentRow.audit2 }}</div>
            <div class="item-list">核对时间：{{ currentRow.audit2Date }}</div>
          </div>
          <div v-else>没有相关信息</div>
        </el-tab-pane>
        <el-tab-pane label="验收信息" name="4">
          <p class="detil-code">工程信息</p>
          <div v-if="acceptanceList.length">
            <el-row>
              <el-col :span="12">
                <div class="item-list">
                  工程编号： {{ currentRow.projectNumber }}
                </div>
              </el-col>
              <el-col :span="12">
                <div class="item-list">
                  工程类型： {{ currentRow.projectType }}
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="item-list">
                  工程名称： {{ currentRow.projectName }}
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="item-list">
                  工程地点： {{ currentRow.projectSite }}
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <div class="item-list">
                  现场管理员： {{ currentRow.manager }}
                </div>
              </el-col>
              <el-col :span="12">
                <div class="item-list">
                  安装施工队： {{ currentRow.install }}
                </div>
              </el-col>
            </el-row>
            <el-table
              ref="acceptanceList"
              :data="acceptanceList"
              style="width: 100%; margin: 15px 0"
              border
              height="calc(100vh - 390px)"
            >
              <el-table-column
                type="index"
                label="序号"
                width="70"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="scope"
                label="看压范围"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="{ row }">
                  {{ row.scope == 1 ? "中压" : "低压" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="realname"
                label="看压人"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="acceptDate"
                label="开始验收时间"
                width="100"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="manager"
                label="看压进度"
                width="150"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="{ row }">
                  {{ row.finish }}/{{ row.acceptSum }}
                  <span
                    v-if="row.isStop"
                    style="color: #1082ff; cursor: pointer"
                    @click="location"
                    >（ 试验终止 ）</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="{ row }">
                  <el-button
                    type="text"
                    size="small"
                    @click="seeacceptanceDetil(row)"
                    >查看详情</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else>没有相关信息</div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <show-img :imgList="imgList" ref="imgList"></show-img>
    <el-dialog
      title="验收详情"
      :visible.sync="acceptanceDialog"
      width="70%"
      :modal="false"
      @closed="addDialogClosed"
      class="detil_dialog"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <el-collapse v-model="activeNameDetil" accordion>
        <el-collapse-item
          :name="index"
          v-for="(item, index) in activeList"
          :key="index"
        >
          <template slot="title">
            {{ item.place }}<el-tag>{{ item.intensity }}</el-tag>
          </template>
          <el-row>
            <el-col :span="12">
              <div class="item-list">创建人： {{ item.createBy }}</div>
            </el-col>
            <el-col :span="12">
              <div class="item-list">创建时间： {{ item.createTime }}</div>
            </el-col>
          </el-row>
          <el-tabs
            v-model="acceptanceName"
            type="card"
            @tab-click="acceptanceClick"
            v-if="activeNameDetil == index"
          >
            <el-tab-pane label="环境判定" name="1">
              <environment
                v-if="acceptanceName == 1"
                :acceptId="item.id"
              ></environment>
            </el-tab-pane>
            <el-tab-pane label="强度试验" name="2">
              <strength
                v-if="acceptanceName == 2"
                :acceptId="item.id"
              ></strength>
            </el-tab-pane>
            <el-tab-pane label="气密试验" name="3">
              <airtight
                v-if="acceptanceName == 3"
                :acceptId="item.id"
              ></airtight>
            </el-tab-pane>
            <el-tab-pane label="吹扫试验" name="4">
              <blow v-if="acceptanceName == 4" :acceptId="item.id"></blow>
            </el-tab-pane>
          </el-tabs>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "@/apis/checkOuter/index.js";
import showImg from "@/components/showImg.vue";
import strength from "./tableList/strength.vue";
import airtight from "./tableList/airtight.vue";
import blow from "./tableList/blow.vue";
import environment from "./tableList/environment.vue";
export default {
  name: "lockout",
  components: { showImg, strength, airtight, blow, environment },
  data() {
    return {
      status: "12",
      activeName: "1",
      time: [],
      searchBox: {
        current: 1,
        size: 100,
        scene: "",
        result: "",
        searchText: "",
        install: "",
        endDate: "",
        startDate: "",
      },
      total: 0,
      detilDialog1: false,
      typeList: [
        { label: "待审核", value: 1 },
        { label: "审核不通过", value: 2 },
        { label: "审核通过", value: 3 },
        { label: "已派工", value: 4 },
        { label: "核对通过", value: 5 },
        { label: "核对驳回", value: 6 },
        { label: "验收中", value: 7 },
        { label: "验收完成", value: 8 },
      ],
      status: {
        4: "试验完成",
        3: "试验完成",
        2: "实验中",
        1: "试验完成",
      },
      tableData: [],
      // 列表数据
      currentRow: {},
      // 图片地址
      imgList: [],
      // 验收列表
      acceptanceList: [],
      // 验收详情弹窗
      acceptanceDialog: false,
      // 折叠面板选择
      activeNameDetil: 0,
      // 详情的tabs值
      acceptanceName: "1",
      // 折叠面板数据
      activeList: [],
      // 压强
      intensity: "",
      // 判断是不是掉接口的数据
      rowType: 0,
    };
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let t1 = year + "-" + month + "-" + day + " 00:00:00";
    let t2 = year + "-" + month + "-" + day + " 23:59:59";
    this.time.push(t1);
    this.time.push(t2);
    this.searchBox.startDate = t1;
    this.searchBox.endDate = t2;
    this.loadList(this.searchBox);
  },
  methods: {
    timeChange(val) {
      if (val) {
        this.searchBox.startDate = val[0];
        this.searchBox.endDate = val[1];
      } else {
        this.searchBox.startDate = null;
        this.searchBox.endDate = null;
      }
    },
    /**
     * 实验终止
     */
    location() {
      // console.log("实验终止");
    },
    // 查看图片
    showImg(row) {
      let file = row.preceptImg.split(",");

      if (file && file.length > 0) {
        let str = file[0];
        if (str.substring(str.length - 4) == ".pdf") {
          file.forEach((item) => {
            this.downfile(item);
          });
        } else {
          this.$refs.imgList.setdialogVisible(true);
          this.imgList = file;
        }
      } else {
        this.$message.error("暂无文件");
      }
    },
    /**
     * 看压详情tabs切换
     */
    async handleClick(val) {
      if (val.index == 3) {
        let { data } = await axiosApi.getAcceptanceInfos({
          id: this.currentRow.id,
        });
        data = data.filter((el) => el.acceptSum != 0);
        this.acceptanceList = data;
      }
    },
    /**
     * 获取验收详情里面的列表数据
     */
    acceptanceClick(val) {
      // console.log(val.index);
    },
    /**
     * 查看详情
     */
    seeDetil(row) {
      this.currentRow = row;
      this.activeName = "1";
      this.detilDialog1 = true;
    },
    /**
     * 查看验收详情
     */
    async seeacceptanceDetil(row) {
      this.rowType = row.type;
      // 掉接口获取详情数据
      if (row.type == 2) {
        this.intensity = row.scope;
        let { data } = await axiosApi.checkDetails({ id: row.id, flag: 1 });
        this.activeList = data;
        this.activeList.forEach((el) => {
          if (el.environment != 1) {
            el.intensity = "环境判定";
          } else if (el.strength != 1) {
            el.intensity = "强度";
          } else if (el.airtight != 1) {
            el.intensity = "气密";
          } else if (el.purge != 1) {
            el.intensity = "吹扫";
          } else {
            el.intensity = "实验完成";
          }
        });
      }
      // 用自身的json数据带入
      if (row.type == 1) {
        this.intensity = row.scope;
        this.activeList = row.pressureApplyHistoryVO.workPressureAcceptList;
        this.activeList.forEach((el) => {
          if (el.environment == 4) {
            el.intensity = "环境判定";
          } else if (el.strength == 4) {
            el.intensity = "强度";
          } else if (el.airtight == 4) {
            el.intensity = "气密";
          } else if (el.purge == 4) {
            el.intensity = "吹扫";
          } else {
            el.intensity = "实验完成";
          }
        });
      }
      this.acceptanceName = "1";
      this.acceptanceDialog = true;
    },
    addDialogClosed() {
      this.acceptanceName = "2";
      this.acceptanceDialog = false;
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    search() {
      this.searchBox.current = 1;
      this.loadList(this.searchBox);
    },
    /**
     * 获取列表
     */
    async loadList(obj) {
      try {
        let { data } = await axiosApi.getAcceptance(obj);
        this.tableData = data.records;
        this.total = data.total;
      } catch (error) {}
    },
    /**
     * 重置
     */
    resetData() {
      this.searchBox = {
        current: 1,
        size: 100,
        scene: "",
        result: "",
        searchText: "",
        install: "",
        endDate: "",
        startDate: "",
      };
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let t1 = year + "-" + month + "-" + day + " 00:00:00";
      let t2 = year + "-" + month + "-" + day + " 23:59:59";
      this.time = [t1, t2];
      this.searchBox.startDate = t1;
      this.searchBox.endDate = t2;
      this.loadList(this.searchBox);
    },
  },
};
</script>

<style scoped lang='less'>
.detil_dialog {
  ::v-deep.el-dialog__body {
    padding: 0 30px 30px 30px;
  }
}
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
  margin-bottom: 15px;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn {
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}
.item-list {
  margin-bottom: 24px;
}
</style>